.panel{
  .panel-header{
    padding-top: 10px;
    padding-bottom: 10px;
    .panel-title{
      margin-bottom: 0;
      font-size: 24px;
      font-weight: 300;
      line-height: 32px;
      display: block;
    }
  }
}