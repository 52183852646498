.metars-page{
  .metar-info-card{
    .panel-heading{
      display:flex;
      justify-content:center;
    }
    &.blank-results{
      .panel-body{
        p{
          font-size: larger;
        }
      }
    }
    &.metar-results{
      .panel-body{
        .metar-panel-section-title{
          display:flex;
          justify-content: center;
        }
        hr{
          border-color: #676767;
        }
      }
    }
    .metar-panel-raw, .metar-panel-raw-labels{
      display: flex;
      align-items: center;
      .metar-panel-raw-text{
        margin: 12px 0 10px;
        min-height: 36px;
        display:flex;
        align-items:center;
      }
      .metar-panel-raw-labels, .metar-panel-decoded-labels{
        line-height: 2;
      }
    }
    .metar-panel-decoded{
      display: flex;
      justify-content: space-around;
      .metar-panel-decoded-labels{
        display:flex;
        align-items: center;
        justify-content: center;
      }
      .metar-panel-decoded-text{
        margin: 0;
      }
      .divider{
        border-left:1px solid #676767;
      }
    }
  }
}

