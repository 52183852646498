.nav-wrapper{
  .input-field {
    input[type=search]{
      height: 56px;
      margin-top: 4px;
      margin-bottom: 4px;
    }
    i{
      height: 56px;
      line-height: 56px;
    }
  }
}

p {
  line-height: 2rem;
  overflow-wrap: break-word;
}

.sidenav-trigger {
  color: #26a69a;
}


@media only screen and (max-width : 600px) {
  #index-banner .section {
    top: 0;
  }
  .nav-wrapper{
    .input-field {
      input[type=search]{
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}

.icon-block {
  padding: 0 15px;
}
.icon-block .material-icons {
  font-size: inherit;
  font-family: 'Material Icons', serif
}

footer.page-footer {
  margin: 0;
}


.filler{
  flex: 1 1 auto;
}

.card{
  .card-reveal{
    .card-title{
      line-height: 32px;
      margin-bottom: 8px;
    }
  }
}

.fixed-image-container{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  .fixed-image{
    opacity: 1;
    position: fixed;
    bottom: 0;
    min-width: 100%;
  }
}
.fixed-image-container:after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #414a4f;
  opacity: 0.75;
}