@import "../src/common/scss/variables";
@import "~tailwindcss/base";
@import "~tailwindcss/utilities";
@import "~tailwindcss/components";
@import "~@materializecss/materialize/sass/components/color-variables";

@import "~@materializecss/materialize/sass/components/variables";
@import "~@materializecss/materialize/sass/components/tabs";
@import "~@materializecss/materialize/sass/components/sidenav";
@import "~@materializecss/materialize/sass/components/tooltip";
@import "~@materializecss/materialize/sass/components/collapsible";
@import "~@materializecss/materialize/sass/components/buttons";
@import "~@materializecss/materialize/sass/components/dropdown";
@import "~@materializecss/materialize/sass/components/datepicker";
@import "~@materializecss/materialize/sass/components/timepicker";
@import "~@materializecss/materialize/sass/components/slider";
@import "~@materializecss/materialize/sass/components/global";
@import "~@materializecss/materialize/sass/components/waves";
@import "~@materializecss/materialize/sass/components/pulse";
@import "~@materializecss/materialize/sass/components/modal";
@import "~@materializecss/materialize/sass/components/navbar";
@import "~@materializecss/materialize/sass/components/grid";
@import '../src/common/scss/fonts';
@import '../src/common/scss/panels';
@import '../src/common/scss/common';
@import '../src/common/scss/headers';
@import '../src/common/scss/cookies_eu';
@import '../src/common/scss/cookies_popup';
@import '../src/metar/scss/metars-page';
@import "~@FlightLevelTech/component-library/app/assets/stylesheets/flt-component-library";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
}

@layer components {
  .button{
    @apply leading-normal text-base py-3 px-4 rounded text-white bg-green-600 hover:bg-green-800 cursor-pointer shadow hover:shadow-xl transition-colors transition-shadow duration-300;
  }
  .cookies-eu{
    color: white;
    background-color: black;
    border-color: black;
    .cookies-eu-ok{
      @apply bg-green-500
      @extend .btn-small;
      @extend .waves-effect;
      @extend .waves-light;
      @extend .pulse;
    }
  }
}

@layer utilities {
  .font-title{
    font-family: 'Racing Sans One', 'Roboto', serif;
  }
}
